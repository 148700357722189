import ReactMarkdown from 'react-markdown/with-html';
import { Link } from 'gatsby';
import React from 'react';
import _ from 'lodash';
import ArrowIcon from '../images/icons/arrow-right.svg';
import { buildImageObj, imageUrlFor } from './sanity/image-url';

function SectionHome(props) {
  let heroImage = _.get(props, 'heroImage.asset');
  let backgroundStyle = null;

  if (heroImage) {
    backgroundStyle = { backgroundImage: `url(${imageUrlFor(buildImageObj(props.heroImage)).url()})` };
  }

  return (
    <section className="hero--full-screen hero--split no-margin hero--alternating hero--aux">
      <div className="hero__image bg--cover bg--rb " style={backgroundStyle}>
      </div>
      <div className="hero__text">
        <div className="text-content headline">
          {props.companyUrl ? (
            <h2 className="headline__headline">
              {
                props.external ?
                  (<a href={props.companyUrl} target="_blank" rel="noopener">{props.title}</a>) :
                  (<Link to={props.companyUrl}>{props.title}</Link>)
              }
            </h2>
          ) : (
              <h2 className="headline__headline">{props.title}</h2>
            )
          }
          <div className="headline__p">
            {props.byline && <ReactMarkdown source={props.byline} />}
          </div>
          {props.companyUrl && (
            <div>
              {
                props.external ?
                  (<a className="icon-outline icon-outline--md" href={props.companyUrl} target="_blank" rel="noopener"><ArrowIcon style={{ fill: '#000000' }} /></a>) :
                  (<Link className="icon-outline icon-outline--md" to={props.companyUrl}><ArrowIcon style={{ fill: '#000000' }} /></Link>)
              }
            </div>
          )}
        </div>
      </div>
    </section>
  );
}

export default SectionHome;
