import React from 'react';
import { graphql } from 'gatsby';
import _ from 'lodash';

import SEO from '../components/seo';
import Wrapper from '../containers/wrapper';
import HeroHome from '../components/hero-home';
import SectionHome from '../components/section-home';
import CompanyTeaser from '../components/company-teaser';

export const query = graphql`
query HomeTemplateQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
        title
        description
        keywords
    }
    home: sanityHome {
        id
        title
        heading
        sectionID
        heroVariations {
            id
            headline
            tagline
        }
        featuredCompanies {
            id
            title
            slug {
                current
            }
            publishedAt
            logo {
                asset {
                    _id
                }
            }
            body
        }
        featuredSections {
            id
            title
            slug {
                current
            }
            publishedAt
            heroImage {
                asset {
                    _id
                }
            }
            byline
            companyUrl
            external
        }
    }
}
`;

const HomeTemplate = props => {
    const { data } = props;

    const siteTitle = _.get(data, 'site.title', 'Homepage');
    const heading = _.get(data, 'home.heading');
    const sectionID = _.get(data, 'home.sectionID');
    const siteDescription = _.get(data, 'site.description', '');
    const siteKeywords = _.get(data, 'site.keywords', []);
    const heroNodes = _.get(data, 'home.heroVariations');
    const sectionNodes = _.get(data, 'home.featuredSections');
    const nodes = _.get(data, 'home.featuredCompanies');

    return (
        <Wrapper>
            <SEO title={siteTitle} description={siteDescription} keywords={siteKeywords} bodyClasses="page-home" bodyId="" />

            {typeof window !== 'undefined' && (
                <div className="home">
                    {heroNodes &&
                        heroNodes.map(node => (
                            <div className="hero hero--main" key={node.id}>
                                <HeroHome {...node} />
                            </div>
                        ))}
                    <section>
                        {sectionNodes &&
                            sectionNodes.map(node => (
                                <SectionHome {...node} key={node.id} />
                            ))}
                    </section>
                    {nodes.length > 0 && (
                        <div className="container">
                            {heading && (
                                <header className="page-heading">
                                    <h2 id={sectionID} className="page-heading__title text-center mt-5">{heading}</h2>
                                </header>
                            )}
                            <div className="row control-break">
                                {nodes.sort(function (a, b) {
                                    var titleA = a.title.toLowerCase();
                                    var titleB = b.title.toLowerCase();

                                    if (titleA < titleB) { return -1; }
                                    if (titleA > titleB) { return 1; }
                                    return 0;
                                }).map(node => (
                                    <div className="col-12 col-sm-4 col-lg-2_4" key={node.id}>
                                        <CompanyTeaser {...node} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
            )}
        </Wrapper>
    );
};

export default HomeTemplate;
