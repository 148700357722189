import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Link } from 'gatsby';
import _ from 'lodash';
import { buildImageObj, imageUrlFor } from './sanity/image-url';

function CompanyTeaser(props) {
  const pageURL = typeof window !== 'undefined' ? window.location.hash.substring(1) : '';

  let founderImage = _.get(props, 'founderImage');
  let body = _.get(props, 'body');
  let hasLogo = _.get(props, 'logo.asset');

  let hasCaseStudy = false;
  if (hasLogo && body) {
    hasCaseStudy = true;
  }

  return (
    <div className="company company-tiny">
      {hasCaseStudy ? (
        <>
          <Link to={`/programs/${props.slug.current}`} className="link" data-tip="Learn more" data-for={`tooltip-${props.slug.current}`} data-offset="{'bottom': 30}">
            {hasLogo ? (
              <img
                className="logo"
                src={imageUrlFor(buildImageObj(props.logo))
                  .width(220)
                }
                alt={props.title}
              />
            ) : (
                <h2 className="mb-0">{props.title}</h2>
              )}
          </Link>
        </>
      ) : (
          <>
            {props.companyUrl ? (
              <a id={props.slug.current} href={props.companyUrl} className={`link ${pageURL === props.slug.current ? 'company-tiny-active' : ''}`} data-tip="View Website" data-for={`tooltip-${props.slug.current}`} target="_blank" rel="noopener noreferrer" data-offset="{'bottom': 30}">
                {hasLogo ? (
                  <img
                    className="logo"
                    src={imageUrlFor(buildImageObj(props.logo))
                      .width(220)
                    }
                    alt={props.title}
                  />
                ) : (
                    <h2 className="mb-0">{props.title}</h2>
                  )}
              </a>
            ) : (
                <div className="link">
                  {hasLogo ? (
                    <img
                      className="logo"
                      src={imageUrlFor(buildImageObj(props.logo))
                        .width(220)
                      }
                      alt={props.title}
                    />
                  ) : (
                      <h2 className="mb-0">{props.title}</h2>
                    )}
                </div>
              )}

          </>
        )}
      <ReactTooltip place="top" type="dark" effect="solid" id={`tooltip-${props.slug.current}`} />
    </div>
  );
}

export default CompanyTeaser;
